@font-face {
  font-family: 'retrokia';
  src: url('./resources/RetrokiaCapsRounded.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: 'retrokia', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  font-family: 'retrokia', sans-serif;
}

@media screen and (max-width: 480px) {
  body p {
    font-size: 8px;
  }
}