body {
  margin: 0;
  font-family: 'retrokia', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  font-family: 'retrokia', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'retrokia';
  src: url('./resources/RetrokiaCapsRounded.ttf') format('truetype');
}
